<template>
    <textarea v-model="model" @paste="handlerPaste"></textarea>
</template>

<script setup>
import { defineModel, nextTick } from 'vue';

const model = defineModel();

const handlerPaste = (event) => {
    // Prevenir el comportamiento predeterminado
    event.preventDefault();

    // Obtener el texto del portapapeles
    const textoPegado = (event.clipboardData || window.clipboardData).getData('text');

    // Realizar el formateo deseado

    const split = textoPegado.split('\n');
    const map = split.map((l) => l.trim()).filter((l) => l !== '');
    const textoFormateado = map.join('\n');

    // Insertar el texto formateado en la posición actual del cursor
    const textarea = event.target;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Actualizar el contenido del textarea en la posición del cursor
    model.value = model.value?.substring(0, start) + textoFormateado + model.value?.substring(end);

    // Ajustar la posición del cursor después de pegar el texto formateado
    nextTick(() => {
        textarea.setSelectionRange(start + textoFormateado.length, start + textoFormateado.length);
    });
};
</script>

<style></style>
