<template>
    <div class="darmin card">
        <div
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="true"
            :aria-controls="`card-${ramId}`"
            ref="header"
        >
            <h5>Autorizaciones</h5>
            <i class="fas fa-lg fa-angle-up"></i>
        </div>
        <div class="card-body">
            <div class="collapse show" :id="`card-${ramId}`" ref="body">
                <div class="row">
                    <div class="col-12 mb-2">
                        <b>Impresión Diagnostica: </b>{{ impresion_diagnostica }}
                    </div>
                    <div class="col-12 mb-4">
                        <label class="col-form-label">S/O Autorizar: </label>
                        <RichTextarea
                            class="form-control border"
                            rows="5"
                            v-model="autorizaciones"
                            :disabled="disabled"
                            :showSearcher="false"
                        />
                    </div>
                    <div class="col-12 mb-4">
                        <label class="col-form-label">Observaciones</label>
                        <RichTextarea
                            class="form-control border"
                            rows="2"
                            v-model="observaciones"
                            :disabled="disabled"
                            :showSearcher="false"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { defineModel, defineProps, onMounted, ref, useTemplateRef } from 'vue';
import RichTextarea from '@/components/form/RichTextarea.vue';

const autorizaciones = defineModel('autorizaciones');
const observaciones = defineModel('observaciones');

defineProps({ impresion_diagnostica: String, disabled: Boolean });

const header = useTemplateRef('header');
const body = useTemplateRef('body');

const ramId = ref(Math.floor(Math.random() * 1000));

onMounted(() => {
    if (!autorizaciones.value) {
        header.value.classList.add('collapsed');
        body.value.classList.remove('show');
    }
});
</script>
