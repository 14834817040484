<template>
    <div class="darmin card">
        <div class="card-body theme-form">
            <div class="row">
                <div class="col-12 d-flex mb-4">
                    <label class="col-sm-3 col-form-label">Entidad</label>
                    <div class="col-sm-9">
                        <InstitucionAutocomplete
                            :class="{ 'is-invalid': v.institucion.$error }"
                            v-model="model.institucion"
                            :disabled="disabled"
                        />
                        <div class="invalid-feedback" v-if="v.institucion.$error">
                            {{ v.institucion.$errors[0].$message }}
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mb-4">
                    <label class="col-md-3 col-form-label">Fecha: </label>
                    <div class="col-md-9">
                        <DateInput v-model="model.date" :onlyRead="disabled" />
                    </div>
                </div>
                <hr />
                <div class="col-12 col-xl-6 mb-4">
                    <label class="col-form-label">Motivo de consulta</label>
                    <textarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.motivo_consulta"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <label class="col-form-label">Enfermedad Actual</label>
                    <textarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.enfermedad_actual"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <label class="col-form-label">Antecedentes Personales</label>
                    <textarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.antecedentes_personales"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <label class="col-form-label">Antecedentes Quirúrgicos</label>
                    <textarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.antecedentes_quirurgicos"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <label class="col-form-label">Antecedentes Familiares</label>
                    <textarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.antecedentes_familiares"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <label class="col-form-label">Revisión de Sistemas</label>
                    <textarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.revision_sistemas"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <hr />
                <div class="col-12 mb-2">
                    <label class="col-form-label">Examen Fisico:</label>
                    <textarea
                        class="form-control border"
                        rows="3"
                        v-model="model.data.examen_fisico"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-2">
                    <label class="col-form-label">CCCC:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.cccc"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-2">
                    <label class="col-form-label">Cardiopulmonar:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.cardiopulmonar"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-2">
                    <label class="col-form-label">Mamas:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.mamas"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-2">
                    <label class="col-form-label">Abdomen:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.abdomen"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-2">
                    <label class="col-form-label">Genito-urinario:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.genito_urinario"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-2">
                    <label class="col-form-label">Musculo-esquelético:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.musculo_esqueletico"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-4">
                    <label class="col-form-label">Neurológico:</label>
                    <textarea
                        class="form-control border"
                        rows="1"
                        v-model="model.data.neurologico"
                        :disabled="disabled"
                    ></textarea>
                </div>
                <div class="col-12 mb-4">
                    <label class="col-form-label">Impresión Diagnostica</label>
                    <RichTextarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.impresion_diagnostica"
                        :disabled="disabled"
                    />
                </div>
                <div class="col-12 mb-4">
                    <label class="col-form-label">Conducta</label>
                    <RichTextarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.conducta"
                        :disabled="disabled"
                        :showSearcher="false"
                    />
                </div>
                <div class="col-12 mb-4">
                    <label class="col-form-label">Plan / Recomendaciones</label>
                    <RichTextarea
                        class="form-control border"
                        rows="4"
                        v-model="model.data.recomendaciones"
                        :disabled="disabled"
                        :showSearcher="false"
                    />
                </div>
            </div>
        </div>
    </div>
    <RecetaCollapseRitchText
        v-model:medicamentos="model.data.medicamentos"
        v-model:observaciones="model.data.medicamentos_observaciones"
    />
    <OrdenesCollapseRitchText
        v-model:autorizaciones="model.data.autorizaciones"
        v-model:observaciones="model.data.autorizaciones_observaciones"
    />
</template>

<script setup>
import { defineModel, defineProps } from 'vue';
import DateInput from '@/components/form/DateInput.vue';
import RichTextarea from '@/components/form/RichTextarea.vue';
import InstitucionAutocomplete from '../../Instituciones/components/InstitucionAutocomplete.vue';
import RecetaCollapseRitchText from '../components/RecetaCollapseRitchText.vue';
import OrdenesCollapseRitchText from '../components/OrdenesCollapseRitchText.vue';

defineProps({ v: Object, disabled: Boolean });

const model = defineModel();
</script>

<style></style>
